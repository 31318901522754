import React from 'react'
import { createRoot } from 'react-dom/client'
import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom'

const container = document.getElementById('root')
const root = createRoot(container!)

function Redir() {

  window.location.assign(`${process.env.NX_WEBAPP3_URL}/auth`)

  return <></>
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Routes>
      <Route
        element={<Redir />}
        path='*'
      />
    </Routes>
    </BrowserRouter>
  </React.StrictMode>,
)
